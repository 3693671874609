/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  certificationsStartLoad,
  removeFetchCertificationsError,
} from "../../../../../../redux/actions/certifications";
import CardsGrid from "../../../../../CardsGrid/CardsGrid";
import { LoadingComponent } from "../../../../../LoadingComponent/LoadingComponent";
import CertificationCard from "./CertificationCard";
export const Certifications = () => {
  const dispatch = useDispatch();
  const handleReload = () => {
    dispatch(certificationsStartLoad());
    dispatch(removeFetchCertificationsError());
  };
  const { collection, shouldFetchCollection, error } = useSelector(
    ({ certifications }) => certifications
  );

  const certificationsData = {
    cardComponent: CertificationCard,
    collection: collection,
    hasPagination: false,
  };

  const loadingComponentData = {
    error,
    errorMsg: "Failed to fetch certifications :(",
    handleReload,
  };

  // First load of projects
  useEffect(() => {
    if (shouldFetchCollection) {
      dispatch(certificationsStartLoad());
    }
  }, [shouldFetchCollection]);

  return collection.length ? (
    <CardsGrid
      data={certificationsData}
      className="certifications"
      cardComponent={CertificationCard}
    />
  ) : (
    <LoadingComponent {...loadingComponentData} />
  );
};
