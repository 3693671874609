import React from "react";
import PropTypes from "prop-types";

const FAB = ({ label, icon, onClick, className, position, isHidden }) => {
  return (
    <button
      onClick={onClick}
      className={`${className} ${position} ${
        isHidden ? "hidden" : ""
      } fab-btn card-shadow`}>
      {label && <>{label}</>}
      {icon && <i>{icon}</i>}
    </button>
  );
};

FAB.defaultProps = {
  label: "",
  className: "",
  onClick: () => {},
  position: "right bottom",
  isHidden: true,
};

FAB.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
};

export default FAB;
