import React from "react";
import PropTypes from "prop-types";

const SkillItem = ({ name, icon, animationDelay }) => {
  return (
    <div
      style={{
        animationDelay: `${animationDelay}ms`,
      }}
      className="skill-item floating-body">
      <h3 className="name">{name}</h3>
      <i className="logo">{icon}</i>
      <div className="circle"></div>
    </div>
  );
};

SkillItem.propTypes = {
  name: "",
  icon: null,
  animationDelay: 0,
};

SkillItem.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  animationDelay: PropTypes.number,
};

export default SkillItem;
