import React from "react";
import { heartIcon, locationIcon } from "../../../assets/svg/svg";
import { SocialNetworks } from "../../SocialNetworks/SocialNetworks";
export const FooterMessage = () => {
  return (
    <div className="footer-message items-center wrapper">
      <p>
        <i>{locationIcon}</i>Ciudad Bolivar, Venezuela
      </p>
      <SocialNetworks />
      <p>
        Made with <i className="heart">{heartIcon}</i> by Angel Figuera
      </p>
    </div>
  );
};
