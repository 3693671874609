import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { certificationsUpdateItem } from "../../../../../../redux/actions/certifications";
import CardImage from "../../../../../CardImage/CardImage";
const CertificationCard = ({
  name,
  company,
  img_url,
  _id: id,
  imgIsLoaded,
  animationDelay,
}) => {
  const dispatch = useDispatch();
  const handleLoadImage = (isLoaded) => {
    dispatch(certificationsUpdateItem(isLoaded, id));
  };
  return (
    <article
      style={{
        animationDelay: `${animationDelay}ms`,
      }}
      className="certification-card floating-body wrapper">
      <CardImage
        scr={img_url}
        alt={name}
        handleLoadImage={handleLoadImage}
        imgIsLoaded={imgIsLoaded}
        className="card-shadow certification-card-img"
      />
      <div className="wrapper info">
        <h3>{name}</h3>
        <h4>- {company} -</h4>
      </div>
    </article>
  );
};

CertificationCard.defaultProps = {
  name: "",
  company: "",
  img_url: "",
  imgIsLoaded: false,
  _id: "",
  animationDelay: 0,
};

CertificationCard.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  img_url: PropTypes.string.isRequired,
  imgIsLoaded: PropTypes.bool.isRequired,
  _id: PropTypes.string.isRequired,
  animationDelay: PropTypes.number,
};

export default CertificationCard;
