import React from "react";
import PropTypes from "prop-types";
import { scrollToSection } from "../../../../helpers/scrollToSection";
const Navbar = ({ links }) => {
  const handleScrollTo = (name) => {
    return () => {
      scrollToSection(name);
    };
  };
  return (
    <nav id="navbar" className="wrapper navbar">
      <ul className="menu">
        {links.map((name, i) => (
          <li key={i}>
            <button onClick={handleScrollTo(name)}>{links[i]}</button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

Navbar.defaultProps = {
  links: [],
};

Navbar.propTypes = {
  links: PropTypes.array.isRequired,
};

export default Navbar;
