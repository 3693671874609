/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { chevronUp, messageIcon } from "../../assets/svg/svg";
import { handleDisplayFAB } from "../../helpers/handleDisplayFAB";
import { scrollFromFABToForm } from "../../helpers/scrollFromFABToForm";
import FAB from "../Button/FAB";
import AboutMe from "./Components/AboutMe/AboutMe";
import Navbar from "./Components/Navbar/Navbar";
const navLinks = ["skills", "portfolio", "contact"];
const Header = () => {
  const [hiddenFAB, setHiddenFAB] = useState({
    sendMessage: true,
    goTop: true,
  });

  useEffect(() => {
    const onScroll = handleDisplayFAB(setHiddenFAB, hiddenFAB);
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onscroll);
    };
  }, []);

  return (
    <header id="header" className="header wrapper">
      <Navbar links={navLinks} />
      <AboutMe />
      <FAB
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
        isHidden={hiddenFAB.goTop}
        icon={chevronUp}
        position="left bottom"
      />
    </header>
  );
};

export default Header;
