import React from "react";
import { mainComponents } from "./Components/main-components";
export const Main = () => {
  return (
    <main className="wrapper main items-center">
      {mainComponents.map(({ component: Component, ...rest }, i) => (
        <Component key={rest.id} {...rest} />
      ))}
    </main>
  );
};
