import { projectsTypes } from "../types/types";
const projectsAPI = process.env.REACT_APP_API_PROJECTS_URL;
export const projectsStartLoad = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: projectsTypes.StartLoading });
      const response = await fetch(projectsAPI);
      if (response.ok) {
        const { collection } = await response.json();
        const sortedCollection = collection
          .sort((a, b) => a.tags.length - b.tags.length)
          .reverse()
          .map((item) => ({
            ...item,
            imgIsLoaded: false,
            tags: item.tags.sort((a, b) => a.length - b.length).reverse(),
          }));
        dispatch(setLoadedProjects(sortedCollection));
      } else {
        const { msg } = await response.json();
        dispatch(setFetchProjectsError(msg));
      }
    } catch (error) {
      dispatch(setFetchProjectsError(error));
    } finally {
      dispatch({ type: projectsTypes.FinishLoading });
    }
  };
};

export const setLoadedProjects = (collection) => ({
  type: projectsTypes.SetLoaded,
  payload: collection,
});

export const showMoreProjects = () => ({
  type: projectsTypes.ShowMore,
});

export const resetDisplayedProjectsLimit = () => ({
  type: projectsTypes.ResetDisplayedLimit,
});

export const setFetchProjectsError = (error) => ({
  type: projectsTypes.SetFetchError,
  payload: error,
});

export const removeFetchProjectsError = () => ({
  type: projectsTypes.RemoveFetchError,
});

export const projectsUpdateItem = (imgIsLoaded, id) => ({
  type: projectsTypes.UpdateItem,
  payload: {
    id,
    imgIsLoaded,
  },
});

export const projectsAddTagToFilter = (tagName) => ({
  type: projectsTypes.AddTagToFilter,
  payload: tagName,
});

export const projectsRemoveTagFromFilter = (tagName) => ({
  type: projectsTypes.RemoveTagFromFilter,
  payload: tagName,
});
