export const projectsTypes = {
  StartLoading: "[Projects] Start loading",
  FinishLoading: "[Projects] Finish loading",
  UpdateItem: "[Projects] Update item - Load image",
  SetLoaded: "[Projects] Set loaded",
  ShowMore: "[Projects] Show more",
  SetFetchError: "[Projects] Set fetch error",
  RemoveFetchError: "[Projects] Remove fetch error",
  ResetDisplayedLimit: "[Projects] Reset displayed limit",
  AddTagToFilter: "[Projects] Add tag to filter",
  RemoveTagFromFilter: "[Projects] Remove tag from filter",
  ResetFilter: "[Projects] Reset filter",
};

export const certificationsTypes = {
  StartLoading: "[Certifications] Start loading",
  FinishLoading: "[Certifications] Finish loading",
  UpdateItem: "[Certifications] Update item - Load image",
  SetLoaded: "[Certifications] Set loaded",
  SetFetchError: "[Certifications] Set fetch error",
  RemoveFetchError: "[Certifications] Remove fetch error",
};

export const randomQuoteTypes = {
  Fetch: "[Random Quote] Fetch new quote",
  StartLoading: "[Random Quote] Start loading",
  FinishLoading: "[Random Quote] Finish loading",
  Set: "[Random Quote] Set quote",
  SetFetchError: "[Random Quote] Set fetch error",
  RemoveFetchError: "[Random Quote] Remove fetch error",
};

export const formTypes = {
  StartLoading: "[Form] Start Loading",
  FinishLoading: "[Form] Finish Loading",
  SetValues: "[Form] Set Form values",
  Success: "[Form] Submit success",
  Error: "[Form] Submit error",
};
