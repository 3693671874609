import { combineReducers } from "redux";
import { certificationsReducer } from "./certificationsReducer";
import { formReducer } from "./formReducer";
import { projectsReducer } from "./projectsReducer";
import { randomQuoteReducer } from "./randomQuoteReducer";
export const rootReducer = combineReducers({
  certifications: certificationsReducer,
  projects: projectsReducer,
  randomQuote: randomQuoteReducer,
  form: formReducer,
});
