import React from "react";
import PropTypes from "prop-types";
import { mySkills } from "./skills-data";
import SkillItem from "./Skill-Item/SkillItem";
import SectionHeader from "../../../SectionHeader/SectionHeader";
import { parseTextToID } from "../../../../helpers/parseTextToID";
export const Skills = ({ id, title, msg }) => {
  let animationDelay = -1000;
  return (
    <section id={parseTextToID(id)} className="my-skills items-center wrapper">
      <SectionHeader title={title} msg={msg} />
      <div className="brands items-center wrapper">
        {mySkills.map((data) => (
          <SkillItem
            animationDelay={(animationDelay += 1000)}
            key={data.name}
            {...data}
          />
        ))}
      </div>
    </section>
  );
};

Skills.defaultProps = {
  id: "",
  title: "",
  msg: <></>,
};

Skills.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  msg: PropTypes.object,
};
