import {
  whatsappIcon,
  telegramIcon,
  gmailIcon,
  twitterLogo,
  githubLogo,
  fiverrLogo,
  linkedInLogo,
} from "../../../../../../assets/svg/svg";
export const contactData = [
  {
    name: "Whastapp",
    info: "+ (58) - 4127966525",
    icon: whatsappIcon,
  },
  {
    name: "Telegram",
    info: "+ (58) - 4127966525",
    icon: telegramIcon,
  },
  {
    name: "Gmail",
    info: "angeleraser@gmail.com",
    icon: gmailIcon,
  },
  {
    name: "Twitter",
    info: "angeleraser",
    icon: twitterLogo,
    url: "https://twitter.com/angeleraser",
  },
  {
    name: "Github",
    info: "angeleraser",
    icon: githubLogo,
    url: "https://github.com/angeleraser",
  },
  {
    name: "Fiverr",
    info: "angeleraser",
    icon: fiverrLogo,
    url: "https://www.fiverr.com/angeleraser",
  },
  {
    name: "LinkedIn",
    info: "angeleraser",
    icon: linkedInLogo,
    url: "www.linkedin.com/in/angeleraser",
  },
];
