import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { parseTextToID } from "../../../../helpers/parseTextToID";
import ComponentSwitcher from "../../../ComponentSwitcher/ComponentSwitcher";
import SectionHeader from "../../../SectionHeader/SectionHeader";
import { portfolioComponents } from "./Components/portfolio-components";
const Portfolio = ({ id, title, msg }) => {
  const {
    projects: { loading: loadingProjects },
    certifications: { loading: loadingCertifications },
  } = useSelector((state) => state);
  return (
    <div id={parseTextToID(id)} className="portfolio items-center wrapper">
      <SectionHeader title={title} msg={msg} />
      <ComponentSwitcher
        disableActions={loadingProjects || loadingCertifications}
        componentList={portfolioComponents}
      />
    </div>
  );
};

Portfolio.defaultProps = {
  id: "",
  title: "",
  msg: <></>,
};

Portfolio.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  msg: PropTypes.object,
};

export default Portfolio;
