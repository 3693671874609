import { useEffect, useMemo, useState } from "react";
import { getItemsByLimit } from "../helpers/getItemsByLimit";
export const useCollection = ({ collection, limit }) => {
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const items = useMemo(
    () =>
      getItemsByLimit({
        collection,
        limit,
      }),
    [limit, collection]
  );
  useEffect(() => {
    if (limit < collection.length) {
      setHasMoreItems(true);
    } else {
      setHasMoreItems(false);
    }
  }, [limit, collection]);
  return [items, hasMoreItems];
};
