import React from "react";

export const CircleSpin = () => {
  return (
    <div className="circle-spin">
      <div className="circle-spin1 circle-spin-child"></div>
      <div className="circle-spin2 circle-spin-child"></div>
      <div className="circle-spin3 circle-spin-child"></div>
      <div className="circle-spin4 circle-spin-child"></div>
      <div className="circle-spin5 circle-spin-child"></div>
      <div className="circle-spin6 circle-spin-child"></div>
      <div className="circle-spin7 circle-spin-child"></div>
      <div className="circle-spin8 circle-spin-child"></div>
      <div className="circle-spin9 circle-spin-child"></div>
      <div className="circle-spin10 circle-spin-child"></div>
      <div className="circle-spin11 circle-spin-child"></div>
      <div className="circle-spin12 circle-spin-child"></div>
    </div>
  );
};
