import React from "react";
import "../../styles/components/Button/Button.css";
import PropTypes from "prop-types";

const Button = ({ color, label, onClick, size, className, icon, type }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`btn ${color} ${className} ${size}`}>
      {icon && <i>{icon}</i>}
      {label}
    </button>
  );
};

Button.defaultProps = {
  color: "white",
  label: "",
  onClick: () => {},
  size: "",
  className: "",
  type: "button",
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.element,
  type: PropTypes.string,
};

export default Button;
