import React from "react";
import PropTypes from "prop-types";
import useLoadImage from "../../hooks/useLoadImage";
import ImageLoading from "./ImageLoading";

const NewImage = ({ url, alt, className, imgIsLoaded, handleLoadImage }) => {
  const [isLoaded, error] = useLoadImage(url, imgIsLoaded, handleLoadImage);
  const image = (
    <img src={url} alt={alt} className={`${className}`} />
  );
  return isLoaded ? image : error ? image : <ImageLoading />;
};

NewImage.defaultProps = {
  url: "",
  alt: "",
  className: "",
  imgIsLoaded: false,
  handleLoadImage: () => {},
};

NewImage.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  imgIsLoaded: PropTypes.bool,
  handleLoadImage: () => {},
};

export default NewImage;
