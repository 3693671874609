import React from "react";
import PropTypes from "prop-types";

const SectionHeader = ({ title, msg }) => {
  return (
    <>
      <h2 className="section-title">{title}</h2>
      <p className="section-message">{msg}</p>
    </>
  );
};

SectionHeader.defaultProps = {
  title: "",
  msg: "",
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  msg: PropTypes.object,
};

export default SectionHeader;
