import React from "react";
import PropTypes from "prop-types";

const ContactItem = ({ icon, info, url, animationDelay }) => {
  return (
    <li
      style={{
        animationDelay: `${animationDelay}ms`,
        cursor: `${url ? "pointer" : "default"}`,
      }}
      className="contact-info__item floating-body">
      {url ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <span>{info}</span>
          <i>{icon}</i>
        </a>
      ) : (
        <>
          <span>{info}</span>
          <i>{icon}</i>
        </>
      )}
    </li>
  );
};

ContactItem.propTypes = {
  info: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

export default ContactItem;
