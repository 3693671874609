import { certificationsTypes } from "../types/types";
const initialState = {
  collection: [],
  shouldFetchCollection: true,
  loading: false,
  error: null,
};

export const certificationsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case certificationsTypes.StartLoading:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case certificationsTypes.FinishLoading:
      return {
        ...state,
        loading: false,
      };
    case certificationsTypes.UpdateItem:
      return {
        ...state,
        collection: state.collection.map((item) =>
          item._id === payload.id
            ? { ...item, imgIsLoaded: payload.imgIsLoaded }
            : item
        ),
      };
    case certificationsTypes.SetLoaded:
      return {
        ...state,
        collection: payload,
        shouldFetchCollection: false,
      };
    case certificationsTypes.SetFetchError:
      return {
        ...state,
        error: payload,
      };
    case certificationsTypes.RemoveFetchError:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
