/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import { useCollection } from "../../hooks/useCollection";
const pagCount = Number(process.env.REACT_APP_PAGINATION_COUNT);
const CardsGrid = ({ data, className, cardComponent: CardComponent }) => {
  const { collection, hasPagination, limit, handleShowMore } = data;
  const [items, hasMoreItems] = useCollection({
    collection,
    limit,
  });
  let animationDelay = -1000;
  const itemsToRender = useMemo(() => {
    const list = hasPagination ? items : collection;
    return list.map((item, index) => {
      if ((index + 1) % pagCount === 0) animationDelay = -1000;
      animationDelay += 1000;
      return (
        <CardComponent
          animationDelay={animationDelay}
          {...item}
          key={item._id}
        />
      );
    });
  }, [items]);
  return (
    <div className="grid-container wrapper">
      <section className={`${className} cards-grid`}>{itemsToRender}</section>
      {hasMoreItems && hasPagination && (
        <Button
          onClick={handleShowMore}
          color="transparent"
          label={`Show more (${collection.length - limit})`}
          size="small"
          className="show-more"
        />
      )}
    </div>
  );
};

CardsGrid.defaultProps = {
  data: {},
  className: "",
  cardComponent: () => <></>,
};

CardsGrid.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  cardComponent: PropTypes.elementType.isRequired,
};

export default CardsGrid;
