import React from "react";
import PropTypes from "prop-types";
import SwitcherNav from "./SwitcherNav";
import useComponent from "../../hooks/useComponent";
import { createContext } from "react";
export const SwitchContext = createContext();
const ComponentSwitcher = ({ componentList, disableActions }) => {
  const [
    { CurrentComponent, currentComponentName, componentNames },
    handleComponentChange,
  ] = useComponent(componentList);
  const providerValue = {
    CurrentComponent,
    currentComponentName,
    componentNames,
    handleComponentChange,
    disableActions,
  };
  return (
    <SwitchContext.Provider value={providerValue}>
      <div className="wrapper component-switcher">
        <SwitcherNav />
        <CurrentComponent />
      </div>
    </SwitchContext.Provider>
  );
};

ComponentSwitcher.defaultProps = {
  componentList: [],
  disableActions: false,
};

ComponentSwitcher.propTypes = {
  componentList: PropTypes.array.isRequired,
  disableActions: PropTypes.bool.isRequired,
};
export default ComponentSwitcher;
