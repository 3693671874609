import { randomQuoteTypes } from "../types/types";
export const startRandomQuoteFetch = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: randomQuoteTypes.StartLoading });
      const response = await fetch("https://api.quotable.io/random");
      const { author, content } = await response.json();
      dispatch(
        setRandomQuote({
          author,
          content,
        })
      );
    } catch (error) {
      dispatch(setRandomQuoteFetchError(error));
    } finally {
      dispatch({ type: randomQuoteTypes.FinishLoading });
    }
  };
};
const setRandomQuote = (quote) => ({
  type: randomQuoteTypes.Set,
  payload: quote,
});
const setRandomQuoteFetchError = (error) => ({
  type: randomQuoteTypes.SetFetchError,
  payload: error,
});
export const removeRandomQuoteFetchError = () => ({
  type: randomQuoteTypes.RemoveFetchError,
});
