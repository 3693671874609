/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterCollectionByTags } from "../../../../../../helpers/filterCollectionByTags";
import { handleShowMore } from "../../../../../../helpers/handleShowMore";
import {
  projectsStartLoad,
  removeFetchProjectsError,
  resetDisplayedProjectsLimit,
  showMoreProjects,
} from "../../../../../../redux/actions/projects";
import CardsGrid from "../../../../../CardsGrid/CardsGrid";
import { LoadingComponent } from "../../../../../LoadingComponent/LoadingComponent";
import FilterBar from "./FilterBar/FilterBar";
import ProjectCard from "./ProjectCard/ProjectCard";
export const Projects = () => {
  const {
    collection,
    displayLimit,
    shouldFetchCollection,
    error,
    filterTags,
  } = useSelector(({ projects }) => projects);

  const dispatch = useDispatch();

  const handleReload = () => {
    dispatch(projectsStartLoad());
    dispatch(removeFetchProjectsError());
  };

  const showMoreAction = useCallback(
    handleShowMore(dispatch, showMoreProjects, {
      cardClassName: "project-card",
    }),
    []
  );

  const filteredCollection = useMemo(() => {
    return filterTags.length > 0
      ? filterCollectionByTags(collection, filterTags)
      : collection;
  }, [filterTags, shouldFetchCollection]);

  const projectsData = {
    cardComponent: ProjectCard,
    collection: filteredCollection,
    hasPagination: true,
    limit: displayLimit,
    handleShowMore: showMoreAction,
  };

  const loadingComponentData = {
    error,
    errorMsg: "Failed to fetch projects :(",
    handleReload,
  };

  // First load of projects
  useEffect(() => {
    if (shouldFetchCollection) {
      dispatch(projectsStartLoad());
    }
  }, [shouldFetchCollection]);

  useEffect(() => {
    filterTags.length === 0 && dispatch(resetDisplayedProjectsLimit());
  }, [filterTags]);

  return collection.length ? (
    <>
      {!!filterTags.length && <FilterBar tags={filterTags} />}
      <CardsGrid
        data={projectsData}
        className="projects"
        cardComponent={ProjectCard}
      />
    </>
  ) : (
    <LoadingComponent {...loadingComponentData} />
  );
};
