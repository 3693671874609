import React from "react";
import { scrollToSection } from "../../../../helpers/scrollToSection";
import Button from "../../../Button/Button";
import { ShareOnSocial } from "../../../ShareOnSocial/ShareOnSocial";
import PersonalInfo from "./PersonalInfo";
const AboutMe = () => {
  return (
    <div className="about-me wrapper">
      <div className="info items-center wrapper">
        <h1>
          Hi, I'm <strong className="strong-gold">Angel Figuera</strong>
        </h1>
        <p className="job">Frontend Developer</p>
        <hr />
        <p className="description">
          Passionate, innovative and proactive developer with about 1 year of
          experience. Daily improvening , constant learning and aplication of
          the best practices through Frontend web development. Proeficent with
          the most modern technologies like{" "}
          <strong className="strong-gold">Javascript ES6+</strong>,
          <strong className="strong-gold"> React JS</strong> and{" "}
          <strong className="strong-gold">Vue js.</strong>
        </p>
        <PersonalInfo />
        <div className="buttons">
          <Button
            onClick={() => {
              scrollToSection("contact");
            }}
            label="Hire me"
            color="white"
          />
          <ShareOnSocial
            label="Share me"
            display="row"
            twitterText="Check out the awesome Angel Figuera's portfolio."
            url="https://angeleraser.vercel.app"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
