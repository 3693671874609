import React from "react";
import { DoubleDotSpin } from "../Spinners/DoubleDotSpin";
import PropTypes from "prop-types";
export const LoadingScreen = ({ loadingSpin: LoadingSpin }) => {
  return (
    <div className="loading-screen wrapper">
      <LoadingSpin />
    </div>
  );
};

LoadingScreen.defaultProps = {
  loadingSpin: DoubleDotSpin,
};

LoadingScreen.propTypes = {
  loadingSpin: PropTypes.elementType.isRequired,
};
