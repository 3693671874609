import React, { useEffect } from "react";
import { wallpaperEl, htmlEl } from ".";
import App from "./App/App";
import { LoadingScreen } from "./components/LoadingScreen/LoadingScreen";
import Particles from "react-particles-js";
import useLoadImage from "./hooks/useLoadImage";
import { wallpaperURL } from "./imagesURL/imagesURL";
import { particleParams } from "./particlesJS/particles";
import { DoubleDotSpin } from "./components/Spinners/DoubleDotSpin";
export const AppWrapper = () => {
  const [isWallpaperLoaded] = useLoadImage(wallpaperURL);
  useEffect(() => {
    if (isWallpaperLoaded) {
      htmlEl.classList.add("is-loaded");
      wallpaperEl.classList.add("is-loaded");
      wallpaperEl.style.backgroundImage = `url(${wallpaperURL})`;
    }
  }, [isWallpaperLoaded]);
  return !isWallpaperLoaded ? (
    <LoadingScreen loadingSpin={DoubleDotSpin} />
  ) : (
    <>
      <Particles params={particleParams} />
      <App />
    </>
  );
};
