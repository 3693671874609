/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { twitterLogo } from "../../../../assets/svg/svg";
import { parseTextToID } from "../../../../helpers/parseTextToID";
import {
  startRandomQuoteFetch,
  removeRandomQuoteFetchError,
} from "../../../../redux/actions/randomQuote";
import { LoadingComponent } from "../../../LoadingComponent/LoadingComponent";
export const RandomQuote = ({ id }) => {
  const dispatch = useDispatch();
  const { quote, error } = useSelector(({ randomQuote }) => randomQuote);
  const loadingComponentData = {
    error,
    errorMsg: "Oopps! An error has ocurred :(",
    handleReload: () => {
      dispatch(removeRandomQuoteFetchError());
      dispatch(startRandomQuoteFetch());
    },
  };
  useEffect(() => {
    if (!quote) {
      dispatch(startRandomQuoteFetch());
    }
  }, []);
  return !quote ? (
    <LoadingComponent {...loadingComponentData} />
  ) : (
    <div
      id={parseTextToID(id)}
      className="wrapper items-center floating-body random-quote">
      <div className="quote-body">
        <i className="fa fa-quote-left"></i>
        <blockquote className="wrapper">
          <p>{quote.content}</p>
          <div className="wrapper">
            <a
              title="Share via Twitter"
              className="share-twitter btn white small"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://twitter.com/intent/tweet?text=${quote.content} -${quote.author}`}>
              <i>{twitterLogo}</i> Share
            </a>
            <h4 className="author">-{quote.author}</h4>
          </div>
        </blockquote>
      </div>
    </div>
  );
};
