import React from "react";
import PropTypes from "prop-types";
// import { useSelector } from "react-redux";
import { parseTextToID } from "../../../../helpers/parseTextToID";
// import ComponentSwitcher from "../../../ComponentSwitcher/ComponentSwitcher";
import SectionHeader from "../../../SectionHeader/SectionHeader";
// import { contactSectionComponents } from "./Components/contact-components";
import ContactInfo from "./Components/Info/ContactInfo";
const Contact = ({ id, title, msg }) => {
  // const { loading } = useSelector(({ form }) => form);
  return (
    <section id={parseTextToID(id)} className="contact wrapper">
      <SectionHeader title={title} msg={msg} />
      <ContactInfo />
    </section>
  );
};

Contact.defaultProps = {
  id: "",
  title: "",
  msg: <></>,
};

Contact.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  msg: PropTypes.object,
};

export default Contact;
