import { projectsTypes } from "../types/types";
const pagCount = Number(process.env.REACT_APP_PAGINATION_COUNT);
const initialState = {
  collection: [],
  shouldFetchCollection: true,
  loading: false,
  displayLimit: pagCount,
  filterTags: [],
  error: null,
};

export const projectsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case projectsTypes.StartLoading:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case projectsTypes.FinishLoading:
      return {
        ...state,
        loading: false,
      };
    case projectsTypes.UpdateItem:
      return {
        ...state,
        collection: state.collection.map((item) =>
          item._id === payload.id
            ? { ...item, imgIsLoaded: payload.imgIsLoaded }
            : item
        ),
      };
    case projectsTypes.SetLoaded:
      return {
        ...state,
        collection: payload,
        shouldFetchCollection: false,
      };
    case projectsTypes.ShowMore:
      return {
        ...state,
        displayLimit: state.displayLimit + pagCount,
      };
    case projectsTypes.ResetDisplayedLimit:
      return {
        ...state,
        displayLimit: Number(process.env.REACT_APP_PAGINATION_COUNT),
      };
    case projectsTypes.SetFetchError:
      return {
        ...state,
        error: payload,
      };
    case projectsTypes.RemoveFetchError:
      return {
        ...state,
        error: null,
      };
    case projectsTypes.AddTagToFilter:
      return {
        ...state,
        filterTags: [...state.filterTags, payload],
      };
    case projectsTypes.RemoveTagFromFilter:
      return {
        ...state,
        filterTags: state.filterTags.filter((name) => name !== payload),
      };
    default:
      return state;
  }
};
