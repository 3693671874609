import React, { useContext } from "react";
import { cloudErrorIcon } from "../../assets/svg/svg";
import Button from "../Button/Button";
import { LoadingContext } from "./LoadingComponent";
export const LoadingComponentError = () => {
  const { handleReload, errorMsg } = useContext(LoadingContext);
  return (
    <div className="loading-component-error wrapper floating-body">
      {errorMsg && <p className="section-message">{errorMsg}</p>}
      <i>{cloudErrorIcon}</i>
      {handleReload && (
        <Button
          onClick={handleReload}
          size="small"
          label={"Reload"}
          color="transparent"
        />
      )}
    </div>
  );
};
