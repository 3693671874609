import React from "react";
import { networks } from "./networks";
export const SocialNetworks = () => {
  let animationDelay = 1;
  return (
    <ul className="social-networks">
      {networks.map((network, _) => (
        <li
          title={network.name}
          style={{
            animationDelay: `${(animationDelay += 0.1)}s`,
          }}
          key={network.name}>
          {" "}
          <a href={network.url} target="_blank" rel="noopener noreferrer">
            <i> {network.logo}</i>
          </a>
        </li>
      ))}
    </ul>
  );
};
