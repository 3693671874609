import { useEffect, useMemo, useRef, useState } from "react";
const useLoadImage = (
  url = "",
  imgIsPrevLoaded = false,
  handleLoadImage = () => {}
) => {
  const isMounted = useRef(true);
  const [isLoaded, setIsLoaded] = useState(imgIsPrevLoaded);
  const [error, setError] = useState(null);
  const newImage = useMemo(() => new Image(), []);
  newImage.onload = () => {
    if (isMounted.current) {
      setIsLoaded(true);
      handleLoadImage(true);
    }
  };
  newImage.onerror = () => {
    if (isMounted.current) {
      setError("Error on load image :(");
      handleLoadImage(false);
    }
  };
  useEffect(() => {
    if (!imgIsPrevLoaded) {
      newImage.src = url;
    }
  }, [newImage, url, imgIsPrevLoaded]);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  return [isLoaded, error];
};

export default useLoadImage;
