export const handleShowMore = (dispacth, action, { cardClassName }) => {
  return ({ target }) => {
    dispacth(action());
//     const cardHeight = document
//       .querySelector(`.${cardClassName}`)
//       .getBoundingClientRect().height;
//     const buttonOffsetTop = target.getBoundingClientRect().top;
//     if (buttonOffsetTop >= cardHeight / 2) {
//       window.scrollBy({ top: cardHeight, behavior: "smooth" });
//     }
  };
};
