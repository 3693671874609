import { useState } from "react";
import { getComponent } from "../helpers/getComponent";
const useComponent = (componentList = []) => {
  const componentNames = componentList.map(({ name }) => name);
  const [currentComponentName, setCurrentComponentName] = useState(
    componentNames[0]
  );
  const componentToShow = getComponent(componentList, currentComponentName);
  const handleComponentChange = (name) => {
    return () => {
      setCurrentComponentName(name);
    };
  };
  return [
    {
      CurrentComponent: componentToShow,
      currentComponentName,
      componentNames,
    },
    handleComponentChange,
  ];
};

export default useComponent;
