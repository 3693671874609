import React from "react";

export const DoubleDotSpin = () => {
  return (
    <div className="double-dot-spin">
      <div className="dot1"></div>
      <div className="dot2"></div>
    </div>
  );
};
