import {
  cssLogo,
  gitLogo,
  hackerrankLogo,
  htmlLogo,
  javascriptLogo,
  reactLogo,
  vueLogo,
  reduxLogo,
  sassLogo,
} from "../../../../assets/svg/svg";

export const mySkills = [
  {
    name: "HTML5",
    icon: htmlLogo,
  },
  {
    name: "CSS3: Flex-Grid",
    icon: cssLogo,
  },
  {
    name: "SASS",
    icon: sassLogo,
  },
  {
    name: "Javascript (ES6)",
    icon: javascriptLogo,
  },
  {
    name: "React JS",
    icon: reactLogo,
  },
  {
    name: "Vue js",
    icon: vueLogo,
  },
  {
    name: "Redux",
    icon: reduxLogo,
  },
  {
    name: "HackerRank (Problem solving)",
    icon: hackerrankLogo,
  },
  {
    name: "GIT",
    icon: gitLogo,
  },
];
