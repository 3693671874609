import { certificationsTypes } from "../types/types";
const certificationsAPI = process.env.REACT_APP_API_CERTIFICATIONS_URL;
export const certificationsStartLoad = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: certificationsTypes.StartLoading });
      const response = await fetch(certificationsAPI);

      if (response.ok) {
        const { collection } = await response.json();
        // throw new Error("Error");
        const mappedCollection = collection.map((item) => ({
          ...item,
          imgIsLoaded: false,
        }));
        dispatch(setLoadedCertifications(mappedCollection));
      } else {
        const { msg } = await response.json();
        dispatch(setFetchCertificationsError(msg));
      }
    } catch (error) {
      dispatch(setFetchCertificationsError(error));
    } finally {
      dispatch({ type: certificationsTypes.FinishLoading });
    }
  };
};

export const setLoadedCertifications = (collection) => ({
  type: certificationsTypes.SetLoaded,
  payload: collection,
});

export const showMoreCertifications = () => ({
  type: certificationsTypes.ShowMore,
});

export const resetDisplayedCertificationsLimit = () => ({
  type: certificationsTypes.ResetDisplayedLimit,
});

export const setFetchCertificationsError = (error) => ({
  type: certificationsTypes.SetFetchError,
  payload: error,
});

export const removeFetchCertificationsError = () => ({
  type: certificationsTypes.RemoveFetchError,
});

export const certificationsUpdateItem = (imgIsLoaded, id) => ({
  type: certificationsTypes.UpdateItem,
  payload: {
    imgIsLoaded,
    id,
  },
});
