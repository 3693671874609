import { randomQuoteTypes } from "../types/types";

const initState = {
  quote: null,
  loading: true,
  error: null,
};

export const randomQuoteReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case randomQuoteTypes.StartLoading:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case randomQuoteTypes.FinishLoading:
      return {
        ...state,
        loading: false,
      };
    case randomQuoteTypes.Set:
      return {
        quote: payload,
        error: null,
      };
    case randomQuoteTypes.SetFetchError:
      return {
        quote: null,
        error: payload,
      };
    case randomQuoteTypes.SetRemoveError:
      return {
        quote: null,
        error: null,
      };
    default:
      return state;
  }
};
