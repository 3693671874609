import React from "react";
import { venezuelaFlag } from "../../../../assets/svg/svg";
import { SocialNetworks } from "../../../SocialNetworks/SocialNetworks";

const PersonalInfo = () => {
  return (
    <div className="personal-info-wrapper">
      <div className="personal-info zoomOut-noOpacity card-shadow wrapper">
        <ul>
          <li>
            <h4>From:</h4>{" "}
            <span className="from">
              Ciudad Bolivar, Venezuela <i>{venezuelaFlag}</i>
            </span>
          </li>
          <li>
            <h4>Status:</h4>{" "}
            <span className="status available">Open to work</span>
          </li>
          <li>
            <ul className="wrapper lang">
              <li>
                <h4>Languages that i speak:</h4>
              </li>
              <li>
                <span>- Spanish (Native)</span>
              </li>
              <li>
                <span>- English (Basic)</span>
              </li>
            </ul>
          </li>
          <li>
            <h4>Focused in:</h4> <span>Mastering React & Vue JS</span>
          </li>
        </ul>
      </div>
      <SocialNetworks />
    </div>
  );
};

export default PersonalInfo;
