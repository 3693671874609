import React from "react";
import { contactData } from "./contact-data";
import ContactItem from "./ContactInfoItem";

const ContactInfo = () => {
  let animationDelay = -1000;
  return (
    <ul className="contact-info wrapper">
      {contactData.map((data, i) => (
        <ContactItem
          animationDelay={(animationDelay += 1000)}
          key={i}
          {...data}
        />
      ))}
    </ul>
  );
};

export default ContactInfo;
