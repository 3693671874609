import React from "react";
import PropTypes from "prop-types";
import NewImage from "../NewImage/NewImage";

const CardImage = ({ scr, alt, handleLoadImage, imgIsLoaded, className }) => {
  return (
    <div className="img-wrapper">
      <NewImage
        url={scr}
        alt={alt}
        imgIsLoaded={imgIsLoaded}
        handleLoadImage={handleLoadImage}
        className={className}
      />
    </div>
  );
};

CardImage.defaultProps = {
  scr: "",
  alt: "",
  className: "",
  handleLoadImage: () => {},
  imgIsLoaded: false,
};

CardImage.propTypes = {
  scr: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  handleLoadImage: PropTypes.func,
  imgIsLoaded: PropTypes.bool,
};

export default CardImage;
