import React, { createContext } from "react";
import PropTypes from "prop-types";
import { LoadingComponentError } from "./LoadingComponentError";
import { DoubleDotSpin } from "../Spinners/DoubleDotSpin";
export const LoadingContext = createContext();
export const LoadingComponent = ({
  error,
  handleReload,
  errorMsg,
  loadingMsg,
}) => {
  const providerValue = {
    handleReload,
    errorMsg,
  };
  return (
    <LoadingContext.Provider value={providerValue}>
      {error ? (
        <LoadingComponentError />
      ) : (
        <div className="wrapper loading-component">
          <DoubleDotSpin />
          {loadingMsg && <p className="section-message">{loadingMsg}</p>}
        </div>
      )}
    </LoadingContext.Provider>
  );
};

LoadingComponent.defaultProps = {
  error: "",
  handleReload: null,
  errorMsg: "Failed to fetch :(",
  loadingMsg: "",
};

LoadingComponent.propTypes = {
  error: PropTypes.any,
  handleReload: PropTypes.func,
  errorMsg: PropTypes.string.isRequired,
  loadingMsg: PropTypes.string,
};
