import { formTypes } from "../types/types";

const initState = {
  loading: false,
  success: false,
  error: null,
  formValues: {
    name: "",
    email: "",
    subject: "",
    message: "",
  },
};

export const formReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case formTypes.StartLoading:
      return {
        success: false,
        error: null,
        loading: true,
      };
    case formTypes.FinishLoading:
      return {
        ...state,
        loading: false,
      };
    case formTypes.Success:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case formTypes.Error:
      return {
        ...state,
        loading: true,
        error: payload,
      };
    case formTypes.SetValues:
      return {
        ...state,
        formValues: {
          ...payload,
        },
      };
    default:
      return state;
  }
};
