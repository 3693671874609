import Contact from "./Contact/Contact";
import Portfolio from "./Portfolio/Portfolio";
import { RandomQuote } from "./RandomQuote/RandomQuote";
import { Skills } from "./Skills/Skills";
export const mainComponents = [
  {
    id: "Random Quote",
    component: RandomQuote,
  },

  {
    id: "Skills",
    title: "My Skills",
    msg: <></>,
    component: Skills,
  },
  {
    id: "Portfolio",
    title: "Portfolio",
    msg: (
      <>
        Here are some of my Projects and certifications that I made and earned
        as a <strong className="strong-gold">Frontend Web Developer</strong>,
        applying the theoretical concepts that I am learn by read books and view
        courses all of which I am very proud and will continue to add and
        building many more.
      </>
    ),
    component: Portfolio,
  },
  {
    id: "Contact",
    title: "Contact Me",
    msg: (
      <>
        Did you like my work and do you want to talk to me? You can email me or
        contact me directly to get my personal contact information, feel free to
        use whatever you think is best, I will reply to you as quickly as
        possible.
      </>
    ),
    component: Contact,
  },
];
