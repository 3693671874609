import React, { useContext } from "react";
import { parseTextToID } from "../../helpers/parseTextToID";
import { SwitchContext } from "./ComponentSwitcher";
const SwitcherNav = () => {
  const {
    componentNames,
    currentComponentName,
    handleComponentChange,
    disableActions,
  } = useContext(SwitchContext);
  return (
    <ul className={`switcher-nav`}>
      {componentNames.map((name, i) => (
        <li
          className={`${name === currentComponentName ? "active" : ""}`}
          key={i}>
          <button
            disabled={disableActions}
            id={parseTextToID(name)}
            onClick={handleComponentChange(name)}>
            {name}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default SwitcherNav;
