import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { handleAddTagToFilter } from "../../../../../../../helpers/handleAddTagToFilter";
const ProjectTags = ({ tags }) => {
  const dispatch = useDispatch();
  const { filterTags } = useSelector(({ projects }) => projects);
  return (
    <ul className="tags">
      {tags.map((tagName) => (
        <li key={tagName}>
          {
            <button
              onClick={handleAddTagToFilter(tagName, dispatch, filterTags)}>
              {tagName}
            </button>
          }
        </li>
      ))}
    </ul>
  );
};

ProjectTags.defaultProps = {
  tags: [],
};

ProjectTags.propTypes = {
  tags: PropTypes.array.isRequired,
};

export default ProjectTags;
