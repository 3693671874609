import {
  fiverrLogo,
  githubLogo,
  linkedInLogo,
  twitterLogo,
} from "../../assets/svg/svg";

export const networks = [
  {
    name: "Github",
    logo: githubLogo,
    url: "https://github.com/angeleraser",
  },
  {
    name: "Twitter",
    logo: twitterLogo,
    url: "https://twitter.com/angeleraser",
  },
  {
    name: "LinkedIn",
    logo: linkedInLogo,
    url: "https://www.linkedin.com/in/angeleraser",
  },
  {
    name: "Fiverr",
    logo: fiverrLogo,
    url: "https://fiverr.com/angeleraser",
  },
];
