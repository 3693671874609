import React, { useState } from "react";
import PropTypes from "prop-types";
import { facebookLogo, shareIcon, twitterLogo } from "../../assets/svg/svg";
import Button from "../Button/Button";

export const ShareOnSocial = ({ url, label, twitterText, display }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="share-on-social">
      <Button
        label={label}
        onClick={() => {
          setIsActive(!isActive);
        }}
        icon={shareIcon}
        size="small"
        color="transparent"
        className={`${isActive ? "active" : ""}`}
      />
      <div className={`links ${isActive ? "active" : ""} ${display}`}>
        <ul>
          <li>
            <a
              title="Share with Facebook"
              onClick={() => {
                setIsActive(false);
              }}
              href={`http://www.facebook.com/share.php?u=${url}`}
              target="_blank"
              rel="noopener noreferrer">
              <i>{facebookLogo}</i>
            </a>
          </li>
          <li>
            <a
              title="Share with Twitter"
              href={`https://twitter.com/intent/tweet?text=${twitterText} Link: ${url}`}
              target="_blank"
              rel="noopener noreferrer">
              <i>{twitterLogo}</i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

ShareOnSocial.defaultProps = {
  url: "#",
  label: "",
  twitterText: "Check out this project made by @angeleraser",
  display: "row",
};

ShareOnSocial.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string,
  twitterText: PropTypes.string,
  display: PropTypes.string,
};
