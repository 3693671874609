import React from "react";
import { CircleSpin } from "../Spinners/CircleSpin";
import PropTypes from "prop-types";
const ImageLoading = ({ loadingSpin: LoadingSpin }) => {
  return (
    <div className="new-img-loading">
      <LoadingSpin />
    </div>
  );
};

ImageLoading.defaultProps = {
  loadingSpin: CircleSpin,
};

ImageLoading.propTypes = {
  loadingSpin: PropTypes.elementType,
};

export default ImageLoading;
