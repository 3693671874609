export const handleDisplayFAB = (setState, currentState) => {
  const header = document.querySelector("#header"),
    contactSection = document.querySelector("#contact"),
    footer = document.querySelector("#footer");
  return () => {
    const headerBottom = header.getBoundingClientRect().bottom;
    const contactSectionTop = contactSection.getBoundingClientRect().top;
    const footerHeight = footer.getBoundingClientRect().height;
    const {
      scrollTop: currentScroll,
      scrollHeight,
      clientHeight,
    } = document.documentElement;
    const maxScrollY = scrollHeight - clientHeight;
    if (currentScroll > maxScrollY - footerHeight) {
      setState({
        sendMessage: true,
        goTop: true,
      });
    } else {
      if (headerBottom < 0) {
        if (currentState.sendMessage && currentState.goTop) {
          setState({
            sendMessage: false,
            goTop: false,
          });
          if (contactSectionTop < 0) {
            setState({
              sendMessage: true,
              goTop: false,
            });
          }
        }
      } else {
        setState({
          sendMessage: true,
          goTop: true,
        });
      }
    }
  };
};
