import { Certifications } from "./Certifications/Certifications";
import { Projects } from "./Projects/Projects";
export const portfolioComponents = [
  {
    name: "Projects",
    component: Projects,
  },
  {
    name: "Certifications",
    component: Certifications,
  },
];
