import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { projectsRemoveTagFromFilter } from "../../../../../../../redux/actions/projects";
const FilterBar = ({ tags }) => {
  const dispatch = useDispatch();
  return (
    <ul className="projects-filter-bar">
      {tags.map((name, i) => (
        <li key={i}>
          <span className="tag">
            {name}
            <button
              onClick={() => {
                dispatch(projectsRemoveTagFromFilter(name));
              }}></button>
          </span>
        </li>
      ))}
    </ul>
  );
};

FilterBar.defaultProps = {
  tags: [],
};

FilterBar.propTypes = {
  tags: PropTypes.array.isRequired,
};

export default FilterBar;
