import React from "react";
import PropTypes from "prop-types";
import {
  attachmentIcon,
  githubLogo,
} from "../../../../../../../assets/svg/svg";
import { projectsUpdateItem } from "../../../../../../../redux/actions/projects";
import CardImage from "../../../../../../CardImage/CardImage";
import ProjectTags from "./ProjectTags";
import { useDispatch } from "react-redux";
import { ShareOnSocial } from "../../../../../../ShareOnSocial/ShareOnSocial";
const ProjectCard = ({
  name,
  url,
  img_url,
  _id: id,
  repository_url,
  tags,
  imgIsLoaded,
  animationDelay,
}) => {
  const dispatch = useDispatch();
  const handleLoadImage = (isLoaded) => {
    dispatch(projectsUpdateItem(isLoaded, id));
  };
  return (
    <article
      style={{
        animationDelay: `${animationDelay}ms`,
      }}
      className="project-card card-shadow floating-body wrapper">
      {/* Project image */}
      <CardImage
        scr={img_url}
        alt={name}
        handleLoadImage={handleLoadImage}
        imgIsLoaded={imgIsLoaded}
        className="project-card-img"
      />
      {/* Project info */}
      <div className="info wrapper">
        <div className="card-header">
          <h4 className="name">{name}</h4>
          <a
            className="visit btn white"
            href={url}
            target="_blank"
            rel="noopener noreferrer">
            <i>{attachmentIcon}</i>
            Visit
          </a>
        </div>
        <ProjectTags tags={tags} />
        <div className="card-footer">
          <ShareOnSocial display="row" url={url} />
          <a
            title="View on Github"
            className="view-repository"
            href={repository_url}
            target="_blank"
            rel="noopener noreferrer">
            <i>{githubLogo}</i>
            View on Github
          </a>
        </div>
      </div>
    </article>
  );
};

ProjectCard.propTypes = {
  name: "",
  url: "",
  img_url: "",
  imgIsLoaded: false,
  repository_url: "",
  tags: [],
  _id: "",
  animationDelay: 0,
};

ProjectCard.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  img_url: PropTypes.string.isRequired,
  imgIsLoaded: PropTypes.bool.isRequired,
  repository_url: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  _id: PropTypes.string.isRequired,
  animationDelay: PropTypes.number,
};

export default ProjectCard;
